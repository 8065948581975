import { ComponentType } from '@angular/cdk/overlay'
import { Injectable, TemplateRef } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  closeAll(): void {
    this.matDialog.closeAll()
  }

  getById(id: string): MatDialogRef<any> {
    return this.matDialog.getDialogById(id)
  }

  open<T>(dialog: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig): MatDialogRef<T> {
    return this.matDialog.open(dialog, config)
  }
}
