/**
 * 💣 Remember to keep those enums values ordered alphabetically
 */

export enum Icon {
  AccountTree = 'account_tree',
  Add = 'add',
  AdminPanelSettings = 'admin_panel_settings',
  AdsClick = 'ads_click',
  ArrowBack = 'arrow_back_ios',
  ArrowDropDown = 'arrow_drop_down',
  ArrowDropUp = 'arrow_drop_up',
  ArrowForward = 'arrow_forward_ios',
  Autorenew = 'autorenew',
  BarChart = 'bar_chart',
  Bookmark = 'bookmark',
  Cached = 'cached',
  Campaign = 'campaign',
  Check = 'check',
  CheckCircle = 'check_circle',
  Close = 'close',
  ContentCopy = 'content_copy',
  CreditCard = 'credit_card',
  DarkMode = 'dark_mode',
  Dashboard = 'dashboard',
  DateRange = 'date_range',
  Delete = 'delete',
  Description = 'description',
  DesignServices = 'design_services',
  Diamond = 'diamond',
  FileDownload = 'download',
  FileUpload = 'upload',
  Forum = 'forum',
  DragIndicator = 'drag_indicator',
  Edit = 'edit',
  Error = 'error',
  Event = 'event',
  Facebook = 'facebook',
  FilterAlt = 'filter_alt',
  FilterAltOff = 'filter_alt_off',
  Flag = 'flag',
  FolderOpen = 'folder_open',
  FormatQuote = 'format_quote',
  GppBad = 'gpp_bad',
  GppGood = 'gpp_good',
  GppMaybe = 'gpp_maybe',
  Help = 'help',
  Home = 'home',
  Image = 'image',
  Info = 'info',
  InsertChart = 'insert_chart',
  Insights = 'insights',
  Instagram = 'instagram',
  Label = 'label',
  LightMode = 'light_mode',
  Link = 'link',
  Linkedin = 'linkedin',
  List = 'list',
  Lock = 'lock',
  Logout = 'logout',
  Mail = 'mail',
  Menu = 'menu',
  MergeType = 'merge_type',
  MoreVert = 'more_vert',
  OpenInNew = 'open_in_new',
  People = 'people',
  Percent = 'percent',
  Person = 'person',
  PieChart = 'pie_chart',
  PlayArrow = 'play_arrow',
  RadioButtonChecked = 'radio_button_checked',
  RadioButtonUnchecked = 'radio_button_unchecked',

  Reply = 'reply',
  ReplyAll = 'reply_all',
  Favorite = 'favorite',
  RocketLaunch = 'rocket_launch',
  Save = 'save',
  SaveAs = 'save_as',
  Schedule = 'schedule',
  Search = 'search',
  Sentiment = 'ar_on_you',
  SentimentPositive = 'sentiment_excited',
  SentimentMixed = 'sentiment_calm',
  SentimentNeutral = 'sentiment_neutral',
  SentimentNegative = 'sentiment_dissatisfied',
  SentimentSettings = 'sentiment_settings',
  SentimentUnclassified = 'sentiment_unclassified',
  Settings = 'settings',
  Share = 'share',
  Sort = 'sort',
  SpaceDashboard = 'space_dashboard',
  Speed = 'speed',
  SupportAgent = 'support_agent',
  Swords = 'swords',
  TrendingDown = 'trending_down',
  TrendingFlat = 'trending_flat',
  TrendingUp = 'trending_up',
  Tune = 'tune',
  Verified = 'verified',
  VideoCam = 'videocam',
  Visibility = 'visibility',
  VisibilityOff = 'visibility_off',
  Wallet = 'wallet',
  Warning = 'warning',
  Title = 'title',
  AddPhotoAlt = 'add_photo_alternate',
  AddChart = 'add_chart',
  AddComment = 'add_comment',
  AddListAlt = 'add_list_alt',
  NoteAdd = 'note_add',
  LucideArrowUp = 'lucide_arrow_up',
  LucideArrowDown = 'lucide_arrow_down',
  WarningAmber = 'warning_amber',
}

/**
 * Icons that are outlined
 * ⚠ Remember to prefix the icon name with "outlined:"
 */
export enum IconOutlined {
  ReplyAll = 'outlined:reply_all',
  Reply = 'outlined:reply',
  AccountTree = 'outlined:account_tree',
  Add = 'outlined:add',
  AdminPanelSettings = 'outlined:admin_panel_settings',
  AdsClick = 'outlined:ads_click',
  ArrowBack = 'outlined:arrow_back_ios',
  ArrowDropDown = 'outlined:arrow_drop_down',
  ArrowDropUp = 'outlined:arrow_drop_up',
  ArrowForward = 'outlined:arrow_forward_ios',
  Autorenew = 'outlined:autorenew',
  BarChart = 'outlined:bar_chart',
  Bookmark = 'outlined:bookmark',
  Cached = 'outlined:cached',
  Campaign = 'outlined:campaign',
  ContentCopy = 'outlined:content_copy',
  Check = 'outlined:check',
  CheckCircle = 'outlined:check_circle',
  Close = 'outlined:close',
  CreditCard = 'outlined:credit_card',
  Favorite = 'outlined:favorite',
  DarkMode = 'outlined:dark_mode',
  Dashboard = 'outlined:dashboard',
  DateRange = 'outlined:date_range',
  Delete = 'outlined:delete',
  Description = 'outlined:description',
  DesignServices = 'outlined:design_services',
  Diamond = 'outlined:diamond',
  FileDownload = 'outlined:download',
  FileUpload = 'outlined:upload',
  Forum = 'outlined:forum',
  DragIndicator = 'outlined:drag_indicator',
  Edit = 'outlined:edit',
  Error = 'outlined:error',
  Event = 'outlined:event',
  Facebook = 'outlined:facebook',
  FilterAlt = 'outlined:filter_alt',
  FilterAltOff = 'outlined:filter_alt_off',
  Flag = 'outlined:flag',
  FolderOpen = 'outlined:folder_open',
  FormatQuote = 'outlined:format_quote',
  GppBad = 'outlined:gpp_bad',
  GppGood = 'outlined:gpp_good',
  GppMaybe = 'outlined:gpp_maybe',
  Help = 'outlined:help',
  Home = 'outlined:home',
  Image = 'outlined:image',
  Info = 'outlined:info',
  InsertChart = 'outlined:insert_chart',
  Insights = 'outlined:insights',
  Instagram = 'outlined:instagram',
  Label = 'outlined:label',
  LightMode = 'outlined:light_mode',
  Link = 'outlined:link',
  Linkedin = 'outlined:linkedin',
  List = 'outlined:list',
  Lock = 'outlined:lock',
  Logout = 'outlined:logout',
  Mail = 'outlined:mail',
  Menu = 'outlined:menu',
  MergeType = 'outlined:merge_type',
  MoreVert = 'outlined:more_vert',
  OpenInNew = 'outlined:open_in_new',
  People = 'outlined:people',
  Percent = 'outlined:percent',
  Person = 'outlined:person',
  PieChart = 'outlined:pie_chart',
  PlayArrow = 'outlined:play_arrow',
  RadioButtonChecked = 'outlined:radio_button_checked',
  RadioButtonUnchecked = 'outlined:radio_button_unchecked',
  RocketLaunch = 'outlined:rocket_launch',
  Save = 'outlined:save',
  SaveAs = 'outlined:save_as',
  Schedule = 'outlined:schedule',
  Search = 'outlined:search',
  Sentiment = 'outlined:ar_on_you',
  SentimentPositive = 'outlined:sentiment_excited',
  SentimentMixed = 'outlined:sentiment_calm',
  SentimentNeutral = 'outlined:sentiment_neutral',
  SentimentNegative = 'outlined:sentiment_dissatisfied',
  SentimentSettings = 'outlined:sentiment_settings',
  SentimentUnclassified = 'outlined:sentiment_unclassified',
  Settings = 'outlined:settings',
  Share = 'outlined:share',
  Sort = 'outlined:sort',
  SpaceDashboard = 'outlined:space_dashboard',
  Speed = 'outlined:speed',
  SupportAgent = 'outlined:support_agent',
  Swords = 'outlined:swords',
  TrendingDown = 'outlined:trending_down',
  TrendingFlat = 'outlined:trending_flat',
  TrendingUp = 'outlined:trending_up',
  Tune = 'outlined:tune',
  Verified = 'outlined:verified',
  VideoCam = 'outlined:videocam',
  Visibility = 'outlined:visibility',
  VisibilityOff = 'outlined:visibility_off',
  Wallet = 'outlined:wallet',
  Warning = 'outlined:warning',
  Title = 'outlined:title',
  AddChart = 'outlined:add_chart',
  AddComment = 'outlined:add_comment',
  AddListAlt = 'outlined:add_list_alt',
  AddPhotoAlt = 'outlined:add_photo_alternate',
  NoteAdd = 'outlined:note_add',
  WarningAmber = 'outlined:warning_amber',
}
