import { Component, EventEmitter, Input, Output, booleanAttribute, input } from '@angular/core'

import { ButtonComponent } from '../button/button.component'

type AlertType = 'success' | 'warning' | 'error' | 'secondary' | 'neutral' | 'primary'
type MarginClassType = 'mb-0' | 'mb-1' | 'mb-2' | 'mb-3' | 'mb-4' | 'mb-5'

@Component({
  selector: 'sb-alert',
  template: `
    <div [class]="classes" role="alert">
      <div class="content">
        <ng-content></ng-content>
      </div>
      @if (dismissible()) {
        <sb-button variant="icon" icon="close" (click)="onClick()" />
      }
    </div>
  `,
  styles: [':host { display: block; }'],
  standalone: true,
  imports: [ButtonComponent],
})
export class AlertComponent {
  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>()
  @Input() marginClass: MarginClassType = 'mb-4'
  variant = input<AlertType>('secondary')
  dismissible = input(false, { transform: booleanAttribute })

  get classes(): string {
    return `alert alert-${this.variant()} ${this.marginClass}`
  }

  onClick(): void {
    this.dismiss.emit()
  }
}
