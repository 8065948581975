import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core'

import { CustomStyle } from '~common/types'

export type TextVariant =
  | 'headlineLarge'
  | 'headlineMedium'
  | 'headlineSmall'
  | 'title'
  | 'subtitle'
  | 'titleLarge'
  | 'body'
  | 'bodyMedium'
  | 'labelLarge'
  | 'label'

@Component({
  selector: 'sb-text',
  template: `
    @switch (variant) {
      @case ('title') {
        <h1 [class]="classes" [ngClass]="ngClasses" [ngStyle]="customStyle">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </h1>
      }
      @case ('subtitle') {
        <h2 [class]="classes" [ngClass]="ngClasses" [ngStyle]="customStyle">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </h2>
      }
      @default {
        <p [class]="classes" [ngClass]="customNgClasses" [ngStyle]="customStyle">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </p>
      }
    }

    <ng-template #content>
      @if (innerHTML) {
        <span [innerHtml]="innerHTML"></span>
      } @else {
        <ng-content></ng-content>
      }
    </ng-template>
  `,
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, NgTemplateOutlet],
})
export class TextComponent {
  @Input() customStyle?: CustomStyle
  @Input() innerHTML: string
  @Input() variant: TextVariant = 'body'
  private _classes = []
  private _error: boolean
  private _inline: boolean = false
  private _muted: boolean
  private _noMargin: boolean
  private _truncate: boolean
  private _truncateLines: boolean
  private _weight: '400' | '500' | '600' | '700'
  @HostBinding('style.overflow') overflow: string

  @Input()
  set align(alignment: 'start' | 'center' | 'end') {
    this._classes.push(`text-${alignment}`)
  }

  get classes() {
    return this._classes.join(' ')
  }

  get customNgClasses() {
    const variant = this.variant
    return {
      ...this.ngClasses,
      'mat-headline-1': variant === 'headlineLarge',
      'mat-headline-2': variant === 'headlineMedium',
      'mat-headline-4': variant === 'headlineSmall',
      'mat-body-1': variant === 'body',
      'mat-body-2': variant === 'bodyMedium',
      'mat-subtitle-1': variant === 'titleLarge',
      'mat-caption': variant === 'label' || variant === 'labelLarge',
      'mat-caption-large': variant === 'labelLarge',
    }
  }

  @Input()
  get error() {
    return this._error
  }

  set error(value: BooleanInput) {
    this._error = coerceBooleanProperty(value)
  }

  get inline() {
    return this._inline
  }

  @Input()
  set inline(value: BooleanInput) {
    this._inline = coerceBooleanProperty(value)
  }

  @Input()
  get muted() {
    return this._muted
  }

  set muted(value: BooleanInput) {
    this._muted = coerceBooleanProperty(value)
  }

  get ngClasses() {
    return {
      'no-margin': this.noMargin || this.inline,
      error: this.error,
      muted: this.muted,
      inline: this.inline,
      truncate: !!this.truncate,
      'truncate-lines': !!this.truncateLines,
      'font-weight-400': this._weight === '400',
      'font-weight-500': this._weight === '500',
      'font-weight-600': this._weight === '600',
      'font-weight-700': this._weight === '700',
    }
  }

  @Input()
  get noMargin() {
    return this._noMargin
  }

  set noMargin(value: BooleanInput) {
    this._noMargin = coerceBooleanProperty(value)
  }

  @Input()
  get truncate() {
    return this._truncate
  }

  set truncate(value: BooleanInput) {
    this._truncate = coerceBooleanProperty(value)
    if (this._truncate) {
      this.overflow = 'hidden'
    }
  }

  @Input()
  get truncateLines() {
    return this._truncateLines
  }

  set truncateLines(value: BooleanInput) {
    this._truncateLines = coerceBooleanProperty(value)
  }

  @Input()
  set weight(weight: '400' | '500' | '600' | '700') {
    this._weight = weight
  }
}
