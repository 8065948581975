import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  booleanAttribute,
  input,
} from '@angular/core'
import { MatCardModule } from '@angular/material/card'

import { CustomStyle } from '~common/types'

@Component({
  selector: 'sb-card',
  template: `
    <ng-template #defaultTitleTemplate>{{ title }}</ng-template>
    <ng-template #defaultSubtitleTemplate>{{ subtitle }}</ng-template>

    <mat-card
      [appearance]="elevated ? 'raised' : 'outlined'"
      [ngClass]="{
        'h-full': fillHeight,
        clickable,
        selectable: selectable(),
        selected: selected(),
        pointer: clickable,
        flat: flat()
      }"
      [ngStyle]="cardStyles()"
    >
      @if (title || subtitle || titleTemplateRef || subtitleTemplateRef) {
        <mat-card-header>
          <mat-card-title>
            <ng-container [ngTemplateOutlet]="titleTemplateRef || defaultTitleTemplate" />
          </mat-card-title>
          <mat-card-subtitle>
            <ng-container [ngTemplateOutlet]="subtitleTemplateRef || defaultSubtitleTemplate" />
          </mat-card-subtitle>
        </mat-card-header>
      }
      @if (imageSrc) {
        <img mat-card-image [src]="imageSrc" />
      }
      <mat-card-content [ngStyle]="contentStyles()" [ngClass]="{ blurred: blurred() }">
        <ng-container [ngTemplateOutlet]="contentTemplateRef" />
        <ng-content />
      </mat-card-content>
      @if (footerTemplateRef) {
        <mat-card-footer>
          <ng-container [ngTemplateOutlet]="footerTemplateRef" />
        </mat-card-footer>
      }
      @if (actionsTemplateRef) {
        <mat-card-actions align="end">
          <ng-container [ngTemplateOutlet]="actionsTemplateRef" />
        </mat-card-actions>
      }
    </mat-card>
  `,
  styles: [
    `
      :host {
        display: block;
        max-width: 100%;
      }

      mat-card-content.blurred {
        filter: blur(6px);
        pointer-events: none;
      }

      mat-card.selectable {
        cursor: pointer;

        &.selected,
        &:hover {
          border-color: #00677e;
        }

        &.selected {
          transform: scale(1.03);
        }
      }

      mat-card.clickable:hover {
        border-color: #00677e;
      }
    `,
  ],
  host: { '[class.h-full]': 'fillHeight' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCardModule, NgClass, NgTemplateOutlet, NgStyle],
})
export class CardComponent {
  cardStyles = input<CustomStyle>({})
  contentStyles = input<CustomStyle>({})
  flat = input(false, { transform: booleanAttribute })
  blurred = input(false, { transform: booleanAttribute })
  selectable = input(false, { transform: booleanAttribute })
  selected = input(false, { transform: booleanAttribute })
  @ContentChild('actions') actionsTemplateRef?: TemplateRef<unknown>
  @ContentChild('content') contentTemplateRef: TemplateRef<unknown>
  @ContentChild('footer') footerTemplateRef?: TemplateRef<unknown>
  @Input() imageSrc: string = null
  @Input() subtitle?: string
  @ContentChild('subtitle') subtitleTemplateRef: TemplateRef<unknown>
  @Input() title?: string
  @ContentChild('title') titleTemplateRef: TemplateRef<unknown>
  private _clickable = false
  private _elevated = false
  private _fillHeight = false

  @Input()
  get clickable() {
    return this._clickable
  }

  set clickable(value: BooleanInput) {
    this._clickable = coerceBooleanProperty(value)
  }

  @Input()
  get elevated() {
    return this._elevated
  }

  set elevated(value: BooleanInput) {
    this._elevated = coerceBooleanProperty(value)
  }

  @Input()
  get fillHeight() {
    return this._fillHeight
  }

  set fillHeight(value: BooleanInput) {
    this._fillHeight = coerceBooleanProperty(value)
  }
}
